const Handlebars = require('handlebars/runtime')
const templates = require('../../../../../../../target/classes/templates/assets/search-templates')
import {addDays, addMinutes, differenceInDays, differenceInMinutes, format, getISODay} from 'date-fns'
import request from '../utils/request'
import {asArray, mapLocationData} from '../utils/helpers'
import Typeahead from './typeahead'
import DestinationsList from './destinations-list'
import PartyList from './party'
import {DATE_FORMAT, EVENTS, LOCATION_TYPES} from '../utils/constants'
import Datepicker from './datepicker'
import {Location, LocationMetaData, OpeningTime, OptionsViewModel, OptionViewModel, PaxTotals} from '../types/types'
import LocationDropdown from './location-dropdown'
import FormHelperService from "../services/form-helper.service"
import {TrackingService} from "../services/tracking.service"
import Flyout from './flyouts'

declare var SearchPanelCarOpeningTimes
const warningMessagesLocations = ['maldives', 'dubai', 'st-lucia', 'st-vincent', 'grenada', 'turks-and-caicos', 'new-york', 'antigua', 'toronto']

export default class SearchForm {
    private bookingType: string
    private bookingTypeId: number | string
    private brand: number | string
    private form: HTMLFormElement
    private errorContainer: HTMLFormElement
    private url: string
    private locationCleared: boolean
    private typeaheads?: Typeahead[] = []
    private locationDropdown?: LocationDropdown
    private destinationsList?: DestinationsList
    private datepickers?: Datepicker[] = []
    private durationInput?: HTMLInputElement
    private directFlightsInput?: HTMLInputElement
    private gatewaysGroup?: HTMLFieldSetElement
    private pickUpLocation?: Location
    private pickUpTimes?: OpeningTime
    private pickUpTimeElement?: HTMLSelectElement
    private dropOffTimes?: OpeningTime
    private dropOffTimeElement?: HTMLSelectElement
    private partyComposition?: PartyList
    private flyouts?: Object = {}
    private ninePlusEnabled?: boolean
    private warningLocations = warningMessagesLocations
    private warningElements: Record<string, HTMLElement | null> | {} = {}

    constructor(selector: Node) {
        try {
            // Set up DOM elements
            this.form = selector as HTMLFormElement
            this.errorContainer = this.form.querySelector('[data-form-error-box]')
            this.brand = this.form.dataset.brand
            this.bookingType = this.form.dataset.bookingType
            this.bookingTypeId = this.form.dataset.bookingTypeId
            this.ninePlusEnabled = (this.form.dataset.nineplusEnabled == 'true')

            // Check for flyouts
            let action = this.form.querySelectorAll(".flyout-wrapper")
            asArray(action).forEach((flyout: HTMLElement) => {
                this.flyouts[flyout.dataset.flyoutKey] = new Flyout(flyout)
            })

            // Check for optional components
            let typeaheadElements: NodeListOf<HTMLElement> = this.form.querySelectorAll('.sp-typeahead')
            let hasTypeaheads: boolean = typeaheadElements.length > 0

            if (hasTypeaheads) {
                for (let i = 0; i < typeaheadElements.length; i++) {
                    this.typeaheads.push(new Typeahead(typeaheadElements[i], this.brand, this.bookingTypeId))
                }
            }

            let locationDropdownElement: HTMLSelectElement = this.form.querySelector('[data-location-emitter]')
            let hasLocationDropdown: boolean = locationDropdownElement != null
            if (hasLocationDropdown) {
                this.locationDropdown = new LocationDropdown(locationDropdownElement, this.brand, this.bookingTypeId)
            }

            let gatewaysGroupElement: HTMLFieldSetElement = this.form.querySelector('[gateways-group]')
            let hasGatewaysGroup: boolean = gatewaysGroupElement != null
            if (hasGatewaysGroup) {
                this.gatewaysGroup = gatewaysGroupElement
            }

            let destinationsListElement: HTMLElement = this.form.querySelector('.sp-destinations-list-container')
            let hasDestinationsList: boolean = destinationsListElement != null
            if (hasDestinationsList) {
                this.destinationsList = new DestinationsList(destinationsListElement, this.brand, this.bookingTypeId, this.bookingType)
            }

            let datepickerElements: NodeListOf<HTMLElement> = this.form.querySelectorAll('[data-search-datepicker]')
            let hasDatepickers: boolean = datepickerElements.length > 0
            if (hasDatepickers) {
                for (let i = 0; i < datepickerElements.length; i++) {
                    this.datepickers.push(new Datepicker(datepickerElements[i]))
                }
            }

            let durationInputElement = this.form.querySelector('[name="duration"]') as HTMLInputElement
            let hasDurationInput: boolean = durationInputElement != null
            if (hasDurationInput) {
                this.durationInput = durationInputElement
            }

            let directFlightsInputElement = this.form.querySelector('[name="direct"]') as HTMLInputElement
            let hasDirectInput: boolean = directFlightsInputElement != null
            if (hasDirectInput) {
                this.directFlightsInput = directFlightsInputElement
            }

            let partyElement: HTMLElement = this.form.querySelector('[data-party-list]')
            let hasParty: boolean = partyElement != null
            if (hasParty) {
                this.partyComposition = new PartyList(partyElement, this.brand, this.bookingTypeId, this.ninePlusEnabled)
            }

            let pickUpTimeElement: HTMLSelectElement = this.form.querySelector('select[name="pickUpTime"]')
            let hasPickUpTime: boolean = pickUpTimeElement != null
            if (hasPickUpTime) {
                this.pickUpTimeElement = pickUpTimeElement
            }

            let dropOffTimeElement: HTMLSelectElement = this.form.querySelector('select[name="dropOffTime"]')
            let hasDropOffTime: boolean = dropOffTimeElement != null
            if (hasDropOffTime) {
                this.dropOffTimeElement = dropOffTimeElement
            }


            this.warningLocations.forEach(locationId => {
                this.warningElements[locationId] = this.form.querySelector(`[data-${locationId}-warning]`)
            })

            // Settings
            let pickUpTimesSettingsElement: HTMLScriptElement | null = this.form.querySelector(`#SearchPanelCarPickUpTimes`)
            if (pickUpTimesSettingsElement) {
                let parsedSettings = JSON.parse(pickUpTimesSettingsElement.innerText)
                this.pickUpTimes = parsedSettings
            }
            let dropOffTimesSettingsElement: HTMLScriptElement | null = this.form.querySelector(`#SearchPanelCarDropOffTimes`)
            if (dropOffTimesSettingsElement) {
                let parsedSettings = JSON.parse(dropOffTimesSettingsElement.innerText)
                this.dropOffTimes = parsedSettings
            }

            // Initialise the form
            this.init()
        } catch (e) {
            console.error('Form cannot be initialised', e)
        }
    }

    private init(): void {
        FormHelperService.friendlyValidation(this.form)

        const handleWarnings = (gatewayDescription, locationDescription) => {
            const heathrowWarnings = ["st-lucia", "antigua", "st-vincent", "grenada", "turks-and-caicos"]

            if (gatewayDescription === 'London Heathrow' && heathrowWarnings.some(warning => RegExp(warning, 'i').test(locationDescription))) {
                heathrowWarnings.forEach(location => {
                    this.setWarning(location)
                })
            } else {
                heathrowWarnings.forEach((warning) => {
                   this.warningElements[warning]?.classList.remove('warning-active');
                });
            }

            if (gatewayDescription === "London Gatwick" && /new-york/ig.test(locationDescription)) {
                this.setWarning('new-york');
            } else {
                this.warningElements['new-york']?.classList.remove('warning-active');
            }
        }

        // If car, set the max date to 28 days from departureDate
        if (this.bookingTypeId == 5) {
            let departureDate = this.datepickers[0].getDate()
            let defaultMaxDate = addDays(departureDate, 29)
            this.datepickers[1].setConfigOption("maxDate", defaultMaxDate)

            // Need to get & store Pick Up location data for cars in this scope
            // to operate with different Drop Off location behavior.

            // Pick up location value
            let locationUrlDescription = this.typeaheads[0].locationUrlDescription

            if (locationUrlDescription) {
                // Get location data
                request.get(`/locations-public-api/search/location/${locationUrlDescription}?bookingType=${this.bookingTypeId}&brand=${this.brand}`)
                    .then(mapLocationData)
                    .then((result: Location) => {
                        this.pickUpLocation = result
                    })
                    .catch(error => {
                        console.error('Error in locations-public-api request', error)
                    })
            }
        }

        this.form.addEventListener('submit', (e: Event) => {
            e && e.preventDefault()
            let isValid: boolean = this.form.checkValidity()
            // Tracking
            this.trackSearch()

            if (isValid) {
                (e.currentTarget as HTMLFormElement).submit()
            } else {
                this.errorContainer.innerHTML = "Oops, something's not quite right. Please fix the below errors and try again."
            }
        })

        // Listen for gateway selection change
        this.form.addEventListener(EVENTS.GATEWAY_SELECT, (e: CustomEvent) => {
            const gatewayDescription = e.detail;
            const locationDescription = this.typeaheads[0].locationUrlDescription;
            handleWarnings(gatewayDescription, locationDescription);
            })

        // Listen for location change
        this.form.addEventListener(EVENTS.LOCATION_SELECT, (e: CustomEvent) => {
            const meta: LocationMetaData = e.detail && e.detail.locationMetadata

            // Set seasonal banner messages
            this.warningLocations.forEach(location => {
                this.setWarning(location)
            })

            if (this.datepickers.length > 0 && meta != null) {
                this.datepickers[0].updateCalendarConfiguration(meta)
                // Update flyouts
                if (this.flyouts[`${this.bookingType}-date`]) {
                    this.flyouts[`${this.bookingType}-date`].updateDate()
                }
            }

            // Update gateways
            if (this.gatewaysGroup && meta.gateways) {
                // Update flyout gateways
                let gatewayOptions: OptionsViewModel = meta.gateways
                let defaultGateway: OptionViewModel = gatewayOptions.options.find(option => option.value === gatewayOptions.selectedValue)
                FormHelperService.updateRadioOptions('gateways-group', 'gateway', this.gatewaysGroup.id, this.gatewaysGroup, gatewayOptions)
                // Enable flyout
                this.flyouts['flights'] && this.flyouts['flights'].enable(defaultGateway.description)

                const gatewayDescription = defaultGateway.description;

                const locationDescription = this.typeaheads[0]?.locationUrlDescription || this.locationDropdown?.selectedValue;
                handleWarnings(gatewayDescription, locationDescription);
            }

            // Store Pick Up location and update Drop Off if different location option hasn't been selected
            if (this.isPickUp(e.target)) {
                this.pickUpLocation = e.detail
                this.typeaheads[1] && this.typeaheads[1].select(this.pickUpLocation)
            }

            // Update depot times
            if (this.pickUpTimeElement && this.isPickUp(e.target) && e.detail.locationMetadata.openingTimes) {
                this.pickUpTimes = e.detail.locationMetadata.openingTimes
                let date = this.datepickers[0].getDate()
                let times: OptionsViewModel = this.generateOpeningOptions(date, this.pickUpTimes)
                FormHelperService.updateSelectOptions(this.pickUpTimeElement, times)

                // Update & enable pick up time flyout
                if (this.flyouts['pickUpDate']) {
                    this.flyouts['pickUpDate'].updateDateTime()
                    this.flyouts['pickUpDate'].enable()
                }
            }

            if (this.dropOffTimeElement && this.isDropOff(e.target) && e.detail.locationMetadata.openingTimes) {
                this.dropOffTimes = e.detail.locationMetadata.openingTimes
                let date = this.datepickers[0].getDate()
                let times: OptionsViewModel = this.generateOpeningOptions(date, this.dropOffTimes)
                FormHelperService.updateSelectOptions(this.dropOffTimeElement, times)

                // Update & enable drop off time flyout
                if (this.flyouts['dropOffDate']) {
                  this.flyouts['dropOffDate'].updateDateTime()
                  this.flyouts['dropOffDate'].enable()
                }
            }

            // Save location field state
            this.locationCleared = false

            // Tracking
            TrackingService.sendEvent({
                name: 'Destination Change',
                holidayType: this.bookingType,
                locationType: (e.detail.locationTypeId === LOCATION_TYPES.HOTEL) ? 'Hotel' : 'Location',
                destination: this.gatherBookingDestination()
            })
        })

        // Listen for location selection from destinations list
        this.form.addEventListener(EVENTS.LOCATION_LIST_SELECT, (e: CustomEvent) => {
            // Update selected location
            if (this.typeaheads.length > 0) {
                this.typeaheads[0].select(e.detail)
            }
        })

        // Listen for location change
        this.form.addEventListener(EVENTS.LOCATION_CLEARED, (e: CustomEvent) => {

            // remove all warning messages when locations are cleared
            for(const key in this.warningElements) {
                this.warningElements[key]?.classList.remove('warning-active');
            };

            // Update gateways
            if (this.gatewaysGroup) {
                // Clear gateways
                this.gatewaysGroup.innerHTML = ""
                // Disable flyout
                this.flyouts['flights'] && this.flyouts['flights'].disable('Choose an airport')
            }

            // Update depot times
            if (this.isPickUp(e.target)) {
                // Clear pick up date
                FormHelperService.clearSelectOptions(this.pickUpTimeElement, 'Time')
                // Disable flyout
                this.flyouts['pickUpDate'] && this.flyouts['pickUpDate'].disable('Select a location')
            }

            if (this.isDropOff(e.target)) {
                // Clear drop off date
                FormHelperService.clearSelectOptions(this.dropOffTimeElement, 'Time')
                // Disable flyout
                this.flyouts['dropOffDate'] && this.flyouts['dropOffDate'].disable('Select a location')
            }

            // Save location field state
            this.locationCleared = true
        })

        // Listen for date change
        this.form.addEventListener(EVENTS.DATE_CHANGED, (e: CustomEvent) => {

            // Update car depots opening times
            if (this.pickUpTimeElement && this.isPickUp(e.target) && this.pickUpTimes['length'] > 0) {
                let duration = this.durationInput && this.durationInput.value
                let times: OptionsViewModel = this.generateOpeningOptions(e.detail, this.pickUpTimes)
                FormHelperService.updateSelectOptions(this.pickUpTimeElement, times)

                const newDropOffDate = addDays(this.datepickers[0].getDate(), duration)
                const onSaleStart = addDays(this.datepickers[0].getDate(), 1)
                const onSaleEnd = addDays(this.datepickers[0].getDate(), 28)
                this.datepickers[1].setConfig(newDropOffDate, onSaleStart, onSaleEnd)

                // Update & enable pick up time flyout
                if (this.flyouts['pickUpDate']) {
                    this.flyouts['pickUpDate'].updateDateTime()
                    this.flyouts['pickUpDate'].enable()
                    this.flyouts['dropOffDate'].updateDateTime()
                    this.flyouts['dropOffDate'].enable()
                }
            }

            if (this.dropOffTimeElement && this.isDropOff(e.target) && this.dropOffTimes['length'] > 0) {
                let times: OptionsViewModel = this.generateOpeningOptions(e.detail, this.dropOffTimes)
                FormHelperService.updateSelectOptions(this.dropOffTimeElement, times)

                // Update & enable drop off time flyout
                if (this.flyouts['dropOffDate']) {
                    this.flyouts['dropOffDate'].updateDateTime()
                    this.flyouts['dropOffDate'].enable()
                }
            }

            // Update the duration
            let pickUpDate = this.pickUpTimeElement && this.datepickers[0].getDate()
            let dropOffDate = this.dropOffTimeElement && this.datepickers[1].getDate()

            if (pickUpDate && dropOffDate) {
                let nights = differenceInDays(dropOffDate, pickUpDate)
                let durationLabelElement = this.form.querySelector('[data-duration]') as HTMLElement

                if ((nights > 0) && durationLabelElement) {
                    let suffix = nights === 1 ? 'night' : 'nights'
                    durationLabelElement.innerHTML = `${nights} ${suffix}`
                    this.durationInput && this.durationInput.value = nights
                }
            }

            // Disable time elements if no location selected
            if (this.locationCleared) {
                this.pickUpTimeElement && this.pickUpTimeElement.setAttribute('disabled', 'true')
                this.dropOffTimeElement && this.dropOffTimeElement.setAttribute('disabled', 'true')
            }

            // Tracking
            TrackingService.sendEvent({
                name: 'Departure Date Change',
                holidayType: this.bookingType,
                startDate: format(this.datepickers[0].getDate(), DATE_FORMAT.OUTPUT),
                duration: this.durationInput && this.durationInput.value
            })
        })

        // Duration change tracking
        this.durationInput && this.durationInput.addEventListener('change', (evt: Event) => {
            TrackingService.sendEvent({
                name: 'Duration Change',
                holidayType: this.bookingType,
                startDate: format(this.datepickers[0].getDate(), 'dd-MM-yyyy'),
                duration: this.durationInput && this.durationInput.value
            })
        })

        // Direct flights input change tracking
        this.directFlightsInput && this.directFlightsInput.addEventListener('change', (evt: Event) => {
            // Tracking
            TrackingService.sendEvent({
                name: 'Direct Flight Toggle',
                holidayType: this.bookingType,
                directFlight: this.directFlightsInput.checked
            })
        })

        // Gateways Group change tracking
        this.gatewaysGroup && this.gatewaysGroup.addEventListener('change', (evt: Event) => {
            let selectedGateway: HTMLInputElement = this.gatewaysGroup.querySelector('input:checked')
            TrackingService.sendEvent({
                name: 'Departure Point Change',
                gateway: [selectedGateway ? selectedGateway.value : undefined]
            })
        })

    }

    private trackSearch() {
        let partyTotals: PaxTotals = this.partyComposition && this.partyComposition.paxTotals
        let gateway: any = undefined

        if (this.gatewaysGroup) {
            let selectedGateway: HTMLInputElement = this.gatewaysGroup.querySelector('input:checked')
            gateway = selectedGateway && selectedGateway.value
        }

        let trackingData = {
            name: 'Search',
            holidayType: this.bookingType,
            gateway: gateway,
            destination: this.gatherBookingDestination(),
            startDate: (this.datepickers && this.datepickers[0]) ? format(this.datepickers[0].getDate(), 'dd-MM-yyyy') : undefined,
            duration: this.durationInput && this.durationInput.value,
            rooms: partyTotals && partyTotals.rooms,
            totalPax: partyTotals && partyTotals.paxTotal,
            totalAdults: partyTotals && partyTotals.totalAdults,
            totalChildren: partyTotals && partyTotals.totalMinors
        }

        if (this.directFlightsInput) {
            trackingData['directFlight'] = this.directFlightsInput.checked
        }

        TrackingService.sendEvent(trackingData)
    }

    private isPickUp(target: EventTarget): boolean {
        let element: HTMLElement = target as HTMLElement
        return (element.dataset.uid && element.dataset.uid.includes('pickUp'))
    }

    private isDropOff(target: EventTarget): boolean {
        let element: HTMLElement = target as HTMLElement
        return (element.dataset.uid && element.dataset.uid.includes('dropOff'))
    }

    private generateOpeningOptions(date: string | Date, openingTimes: OpeningTime): OptionsViewModel {
        let results: OptionsViewModel = {
            options: [],
            selectedValue: ""
        }
        let isoDay = getISODay(date)
        let times: OpeningTime = openingTimes.find(x => x.isoDayOfWeek == isoDay)
        let startArr = times && times.start.split(":")
        let endArr = times && times.end.split(":")
        let start: Date = new Date().setHours(startArr && startArr[0], startArr && startArr[1], 0, 0)
        let end: Date = new Date().setHours(endArr && endArr[0], endArr && endArr[1], 0, 0)
        let openingRangeInMinutes: number = differenceInMinutes(end, start)
        let incrementSteps: number = openingRangeInMinutes / 15
        let current: Date = start
        for (let i = 1; i < incrementSteps; i++) {
            let nextIncrement = addMinutes(current, 15)
            results.options.push({
                value: format(nextIncrement, "HH:mm"),
                description: format(nextIncrement, "HH:mm")
            })
            current = nextIncrement
        }

        return results
    }

    private gatherBookingDestination(): string {
        let destination: string

        if (this.bookingTypeId === 5) {
            let pickUp = (this.typeaheads[0] && this.typeaheads[0].locationUrlDescription) || 'None'
            let dropOff = (this.typeaheads[1] && this.typeaheads[1].locationUrlDescription) || 'None'
            destination = `${pickUp} - ${dropOff}`
        } else {
            let locationInput: HTMLInputElement = this.form.querySelector('[name="location"]') as HTMLInputElement
            destination = locationInput && locationInput.value
        }

        return destination
    }

    private setWarning(locationIdentifier: string): void {
        if (!this.warningElements[locationIdentifier]) return

        const locationIdFromField = this.typeaheads[0]?.locationUrlDescription
            || this.typeaheads[0]?.parentName
            || this.locationDropdown.selectedValue

        const isMatchedLocation = RegExp(locationIdentifier, 'ig').test(locationIdFromField)

        isMatchedLocation
            ? this.warningElements[locationIdentifier].classList.add('warning-active')
            : this.warningElements[locationIdentifier].classList.remove('warning-active')
    }

}